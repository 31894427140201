body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
}

.resume {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.header-title {
  margin: 0;
  color: #333;
}

.header-nav {
  list-style: none;
  padding: 0;
  margin: 0;
}

.header-nav-item {
  display: inline-block;
  margin-right: 10px;
}

.section {
  margin-bottom: 40px;
}

.section h2 {
  color: #333;
}

.content {
  color: #666;
}

.label {
  font-weight: bold;
}

.footer {
  margin-top: 40px;
  text-align: center;
  color: #999;
}
